// const lovloginpath = "http://localhost/gf_work/gf_keys/LOV-ASM-Tool/backend/api/ajax/living_our_values.php";
// const bedloginpath = "http://localhost:3001/login";
// const lov_url = ":3002";
// const bed_url = ":3003";
// const rooturl = 'http://localhost';

const lovloginpath = "https://mykeysatgf.com/api/ajax/living_our_values.php";
const bedloginpath = "https://bettereveryday.azurewebsites.net/login";
const lov_url = "/lov";
const bed_url = "/better_every_day";
const rooturl = '';

export {
    lovloginpath,
    bedloginpath,
    lov_url,
    bed_url,
    rooturl
};