export default function Validation(name,value){
    const regex = {
            'name' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z])[a-zA-Z '~?!@]{2,}$/g,
            //'lastName' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
            'email' : /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[a-zA-Z]{2,6}$/g,
            'uname' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9~!@#$%^&*-_+=?.]{2,}$/g,
            'pword' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9~!@#$%^&*-_+=?.]{2,}$/g,
            'answer1' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '\s.#/$%@&)(^\-,!]{2,}$/g,
            'answer2' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '\s.#/$%@&)(^\-,!]{2,}$/g,
            'projectname' : /^(?!\s)(?!.*\s$)(?=[a-zA-Z0-9]*)[a-zA-Z0-9 ')(}{~!@#$^\-_+=]{2,}$/g,
            'projectcontact1' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
            'projectcontactcompany' : /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@]{2,}$/g,
            'projectcontactemail' : /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]){0,}@[A-Za-z0-9-]+\.([A-Za-z]{1,6}\.)?[a-zA-Z]{2,6}$/g,
            'projectcontactphone' : /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g,
            'pageRange' : /^(([ 0-9]|\-[0-9]+)+(?!\s;$)(;?)){1,}$/g,
            'noPic' : /^sizes:[A-Za-z]{1,}(,{1}[A-Za-z]{1,})*;{1}colors:[A-Za-z]{1,}(,{1}[A-Za-z]{1,})*$/,
            'color' : /^[A-Za-z\s]{1,}$/,
            'size' : /^[A-Za-z0-9]{1,}(,{1}\s*[A-Za-z0-9]{1,})*$/,
            // 'date' : /^\d{2}\/\d{2}\/\d{4}$/,
            'date' : /^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([2-9])(\d{1})|(20)([012])(\d{1})|([89012])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([2-9])(\d{1})|(20)([012])(\d{1})|([89012])(\d{1})))$/g,
            'reason' : /^(?=.*[a-zA-Z0-9\s])[a-zA-Z0-9 '\s.#/$%:*@&)(^\-,!`~_=+{}\[\]\\|"><?]{2,}$/g,
            'prize' : /^(?=.*[a-zA-Z0-9\s])[a-zA-Z0-9 '\*\.\-,#$%]{2,}$/g,
            'posInteger' : /^(\s*|[0]|[1-9][0-9]*)$/,
            'callcenter' : /^(\s*|[a-zA-Z0-9]*)$/g,
            'department' : /^(\s*|[a-zA-Z]{1,}(((\-?[a-zA-Z]+)*|(\/?[a-zA-Z]+)*)?|[a-zA-Z]*))$/g,
            'street_address':/[\w',-\\/.\s#]{5,}/g,
            'city':/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
            'zipcode':/^\d{5}(?:[- ]\d{4})?$/
    };
    if(name in regex){
      return regex[name].test(value);                
    }else{
      return true;
    }
}
