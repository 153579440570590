import React, { useEffect, useContext, useState } from 'react';
import HeaderMain from './Components/header';
import Footer from './Components/footer';
import LoginPage from './Containers/LoginPage';
import LandingPage from './Containers/LandingPage';
import CustomModal from './Components/Modal/Modal';
import './App.css';
import axios from 'axios';
import { lovloginpath } from './Constants/routes';

const initialState = {
	userInfo: null,
    page: 'LoginPage',
    loading: false,
    loadingMessage: '',
    loadingSuccess: false
}

function deepCopy(obj){
	let newObject;
	if (obj === null || typeof obj !== "object"){
		return obj;
	}
	if (obj instanceof Array) {
        newObject = [];
        for (var i = 0; i < obj.length; i++) {
            newObject[i] = deepCopy( obj[i] );
        }
    } else if (obj instanceof Object) {
        newObject = {};
        for ( const key of Object.keys(obj) ) {
            newObject[key] = deepCopy( obj[key] );
        }
    }
    return newObject;
}

function App() {

	const [ state, stateUpdate ] = useState({
        lovUserInfo: null,
		bedUserInfo: null,
		page: 'LoginPage',
		loading: false,
		loadingMessage: '',
		loadingSuccess: false,
		recover: false,
		reset: false,
		firstLogin: false
    });
    const updateState = (fields) => {
        stateUpdate(prevState => ({ ...deepCopy(prevState), ...fields }));
    }

	let content;

	switch( state.page ){
		case 'LandingPage':
            content = <LandingPage setState={ updateState } bedUserInfo={ state.bedUserInfo } lovUserInfo={ state.lovUserInfo } />;
        break;
		case 'LoginPage':
            content = <LoginPage setState={ updateState }/>;        
        break;
		default:
			content = <LoginPage setState={ updateState }/>; 
	}

	return (
		<>
			{
				state.page !== 'LoginPage' && 
				<>
					<HeaderMain/>
					<Footer/>
				</>
			}
            {content}
			<CustomModal
                loading={ state.loading }
                loadingMessage={ state.loadingMessage }
                success={ state.loadingSuccess }
                setState={ updateState }
            />
		</>
	);
}

export default App;
