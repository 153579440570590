import {Nav} from 'react-bootstrap';
// import image3 from '../images/3.png';
// import image1 from '../images/1.png';
// import image4 from '../images/store.png';
import image1 from '../images/icons-01.png';//better every day
import image3 from '../images/icons-02.png'; //living our values
import image2 from '../images/2.png'; //asm promotional items
import image4 from '../images/icons-03.png';//shopping
import  '../styles.css';
import {
    lov_url,
    bed_url,
	rooturl
} from '../Constants/routes';


export default function LandingPage(props) {
	const firstPage = props.userType === 'user' ? 'SuggestionCollaborate' : 'PendingSuggestions';
	let user = props.lovUserInfo.user_type;
	console.log(user);
	let shouldHaveAsm = (user === 'asm' || user === 'asm_regional' || user === 'asm_admin' || props.lovUserInfo.name === "Heidi DeFazio" || user === 'sys_admin');
	let bedURIcomp = encodeURIComponent( btoa( JSON.stringify( props.bedUserInfo ) ) );
	console.log(props.lovUserInfo);
	let lovURIcomp = encodeURIComponent( btoa( JSON.stringify( props.lovUserInfo ) ) );
	let urlparams = "&linfo=" + lovURIcomp + "&binfo=" + bedURIcomp;

	const bedurl = rooturl + bed_url + "/?app=bed" + urlparams;
	const lovurl = rooturl + lov_url + "/?app=lov" + urlparams;
	const asmurl = rooturl + lov_url + "/?app=asm" + urlparams;
	const shopurl = rooturl + lov_url + "/?app=shop" + urlparams;

	console.log(bedurl);

	return (

		<div className="container-Fluid" style ={{ position:'relative'}}>                  
			{/* <img src={require('./125-1.jpg')} style={{width:'100%', height:'450px'}}/> */}

			<p style={{position:'absolute', marginTop:'-350px', marginLeft:'70px', zIndex:'999', color:'#fff',fontSize:'28px'}}>MY KEYS APPLICATION 
			</p>

			<p style={{position:'absolute', marginTop:'-300px', marginLeft:'70px', zIndex:'999', color:'#fff',fontSize:'14px'}}>BECOME BETTER EVERYDAY
			</p>

			<p style={{position:'absolute', marginTop:'-250px', marginLeft:'70px', zIndex:'999', color:'#fff',fontSize:'12px',fontWeight:'sharp', textAlign:'center', border:'2px solid #fff',padding:'10px'}}>MORE INFORMATION
			</p>

			<div className="container">
				<div className='row'>
					<div className="col-md-12 "  style={{border:'0px solid #cacaca',marginTop:'4rem'}}>
						{/* <p style={{textAlign:'center',fontSize:'25px',fontWeight:'bold', color:' #003E51',marginBottom:'15px',paddingTop:'0px'}}>SELECT THE APPLICATION YOU WOULD LIKE TO SEE</p> */}
						<div className='row align-items-center justify-content-center' style={{marginBottom:'0px'}}>
							<div className="col-lg-4 col-md-8 app_nav_container">
								<Nav.Link href={ bedurl } >
									<div className="app_img_name_container">
										<img src={ image1 } />
										<p className="app_choice_name">Better Every Day</p>
									</div>
								</Nav.Link>
							</div>
							<div className="col-lg-4 col-md-8 app_nav_container">
								<Nav.Link href={ lovurl }>
									<div className="app_img_name_container">
										<img src={ image3 } />
										<p className="app_choice_name ">Living Our Values</p>
									</div>
								</Nav.Link>
							</div>
							{
								shouldHaveAsm && 
								<div className="col-lg-4 col-md-8 app_nav_container">
									<Nav.Link href={ asmurl }>
										<div className="app_img_name_container">
											<img src={ image2 } />
											<p className="app_choice_name">GF Promotional Orders</p>
										</div>
									</Nav.Link>
								</div>
							}
							<div className="col-lg-4 col-md-8 app_nav_container">
								<Nav.Link href={ shopurl }>
									<div className="app_img_name_container">
										<img src={ image4 } />
										<p className="app_choice_name ">Keys Reward Shop</p>
									</div>
								</Nav.Link>
							</div>
						</div>
					</div>	
				</div>
			</div>
		</div>

  )
}