import { Nav } from 'react-bootstrap';
import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import '../styles.css';
import {
    lovloginpath,
    bedloginpath
} from '../Constants/routes';
import GF_Keys_Logo from '../images/LOV 2022 two_keys copy3 copy.png';
// import GF_Keys_Logo from '../images/GF_Keys.png';
// import Sparkle_Cloud_Progress_RGB from '../images/Sparkle_Cloud_Progress_RGB.png';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { CiLock } from 'react-icons/ci';
import { BsArrowLeftCircle } from 'react-icons/bs';
import Validation from '../Validation';

export default function LoginPage(props) {
	const passRef = useRef();
	const emailRef = useRef();

	useEffect(()=>{
		const url = new URL(window.location.href);
		const bed_info = url.searchParams.get('binfo');
		const lov_info = url.searchParams.get('linfo');
		if( bed_info || lov_info ){
			const binfo = JSON.parse( atob( decodeURIComponent( bed_info ) ) );
			const linfo = JSON.parse( atob( decodeURIComponent( lov_info ) ) );
			window.history.replaceState(null, '', window.location.pathname);
			props.setState({ bedUserInfo: binfo, lovUserInfo: linfo, page: 'LandingPage', loading: false, loadingSuccess: true });
		}
	}, []);

	const [ subPage, setSubPage ] = useState('Login');//Login
	const [ uid, setUid ] = useState(0);

	function handleLogin(){
		let email = emailRef.current.value.trim();
		if( email !== '' && passRef.current.value !== '' ){
			props.setState({ loading: true });
			const pass = passRef.current.value;
			axios({
				method: 'post',
				//url: 'http://137.184.232.145:3001/login',
				url: lovloginpath,
				//url: 'http://localhost:3001/login',
				data: {
					name: email.toLowerCase(),
					kind: pass,
					endpoint: "info",
					table: "users",
					action: "login"
				}
			}).then( response => {
				//lov
				console.log(response);
				const info = response.data.data[0];
				if( info.Message === "success" ){ //login is a success, pass up userinfo 
					props.setState({ lovUserInfo: info });

					axios({
						method: 'post',
						url: bedloginpath,
						data: {
							email: email.toLowerCase(),
							password: pass
						}
					}).then( res => {
						console.log(res);
						if( res.data.success ){
							props.setState({ bedUserInfo: {...res.data.user, lovId: info.userID}, page: 'LandingPage', loading: false, loadingSuccess: true });
							// if( res.data.userType.toLowerCase() === 'admin' ){
								
							// }
						} else {
							props.setState({
								loadingMessage: res.data.message,
								loadingSuccess: false,
								loading: false
							});
						}
					}).catch( err => {
						console.log(err);
						props.setState({
							loadingMessage: "There was an error when attempting to retrieve Better Every Day info.",
							loadingSuccess: false,
							loading: false
						});
					});

				} else if ( info.Message === "first" ){
					props.setState({ lovUserInfo: info });
					setUid(info.userID);
					axios({
						method: 'post',
						url: bedloginpath,
						data: {
							email: email.toLowerCase(),
							password: pass
						}
					}).then( res => {
						console.log(res);
						if( res.data.success ){
							props.setState({ 
								bedUserInfo: {...res.data.user, lovId: info.userID}, 
								loading: false, 
								loadingSuccess: true
							});
							setSubPage('FirstLogin');
							// if( res.data.userType.toLowerCase() === 'admin' ){
								
							// }
						} else {
							props.setState({
								loadingMessage: res.data.message,
								loadingSuccess: false,
								loading: false
							});
						}
					}).catch( err => {
						console.log(err);
						props.setState({
							loadingMessage: "There was an error when attempting to retrieve Better Every Day info.",
							loadingSuccess: false,
							loading: false
						});
					});
				} else {
					props.setState({
						loadingMessage: info.Message,
						loadingSuccess: false,
						loading: false
					});
				}
					
			}).catch( err => {
				console.log(err);
				props.setState({
					loadingMessage: "There was an error when attempting to log in.",
					loadingSuccess: false,
					loading: false
				});
			});
		} else {
			props.setState({ loadingMessage: "please enter a valid username and password", loadingSuccess: false });
		}
	}

	function handleRecover(email){
		props.setState({ loading: true });
		if( email.trim() !== '' && Validation("email", email) ){ //validate input field, make sure it is an email address
			axios({
				method: 'post',
				url: lovloginpath,
				headers: { 'Content-Type': 'application/json' },
				data: {
					endpoint: 'info',
					table: 'users',
					action: 'recover',
					name: email
				}
			}).then((response)=>{
				let data = response.data.data;
				console.log(data);
				props.setState({
					loadingMessage: "If an account with that email exists, you will receive an email with your current password.",
					loadingSuccess: true,
					loading: false
				});
			});
		}else{
			props.setState({
				loadingMessage: "please enter a valid email address",
				loadingSuccess: false,
				loading: false
			});
		}
	}

	function handleReset({stage, callback, setState}){
		if( stage === 'initial' ){
			let email = emailRef.current.value.trim();
			if( email !== '' && Validation("email", email) && passRef.current.value !== '' ){
				callback(); // getQuestion
			} else {
				setState({ loadingMessage: "please enter a valid username and password", loadingSuccess: false, loading: false });
			}
		} else if( stage === 'new_password' ){
			callback(); //setNewPassword
		}
	}

	function handleEnter(e, resetStage, callback){
		if( e.key === "Enter" ){
			if( subPage === 'Login' ){
				handleLogin();
			} else if( subPage === 'Reset' ){
				handleReset({stage: resetStage, callback: callback, setState: props.setState});
			} else if( subPage === 'Recover' ){
				handleRecover();
			}
		}
	}

	return (
		<>
			<div className="login-page-background"></div>
			<div className="loginPage">
				<div className="graphic-container">
					<h3 className="my-keys-title">mykeys@GF</h3>
					<img src={ GF_Keys_Logo } />
				</div>
				<div  className="login-container" >
					<div className="login-container-elements">
						{
							subPage === 'Login' && 
							<UserLogin
								emailRef={ emailRef }
								passRef={ passRef }
								handleEnter={ handleEnter }
								handleLogin={ handleLogin }
								setSubPage={ setSubPage }
							/>
						}
						{
							subPage === 'Recover' && 
							<RecoverPassword
								emailRef={ emailRef }
								passRef={ passRef }
								handleEnter={ handleEnter }
								handleRecover={ handleRecover }
								setSubPage={ setSubPage }
							/>
						}
						{
							subPage === 'Reset' && 
							<ResetPassword
								emailRef={ emailRef }
								passRef={ passRef }
								handleEnter={ handleEnter }
								handleReset={ handleReset }
								setSubPage={ setSubPage }
								setState={ props.setState }
							/>
						}
						{
							subPage === 'FirstLogin' && 
							<SetUserLogin 
								setState={ props.setState }
								userID={ uid }
							/>
						}
					</div>
				</div>
			</div>
		</>
	);
}

function UserLogin(props){
	const {
		emailRef,
		passRef,
		handleEnter,
		handleLogin,
		setSubPage
	} = props;
	return (
		<>
			<div className="login-icon-container">
				<HiOutlineUserCircle />
			</div>
			<h3 className="my-keys-signin-title">Sign In</h3>
			<div className="login-input-container">  
				<div>
					<label className="login-input-label" name="userName" id="userName">Email</label>
				</div>
				<div>
					<input className="login-input" id="user" type="text" ref={emailRef}/>
				</div>			
			</div>
			<div className="login-input-container">
				<div>
					<label className="login-input-label" id="password" >Password</label>
				</div>
				<div>
					<input className="login-input" type="password" id="pass" ref={passRef} onKeyDown={(e) => handleEnter(e)}/>
				</div>			
			</div>
			<div className="login-buttons-container">
				<button onClick={() => handleLogin()} className="login-button" >Sign In</button>
				<div className="account-actions-container">
					<button className="account-actions-button" onClick={ ()=>setSubPage('Recover') }>Recover Password</button>
					<button className="account-actions-button" onClick={ ()=>setSubPage('Reset') }>Reset Password</button>
				</div>
			</div>
		</>
	)
}

function RecoverPassword(props){
	const { 
		handleRecover,
		emailRef,
		handleEnter,
		setSubPage
	} = props;
	console.log(emailRef.current.value.trim().toLowerCase());
	return (
		<>
			<div className="login-icon-container lock-icon">
				<CiLock />
			</div>
			<h3 className="my-keys-signin-title">Recover Password</h3>
			<div className="login-input-container">  
				<div>
					<label className="login-input-label" name="userName" id="userName">Email</label>
				</div>
				<div>
					<input className="login-input" id="user" type="text" ref={emailRef}/>
				</div>			
			</div>
			<div className="login-buttons-container">
				<button onClick={() => handleRecover(emailRef.current.value.trim().toLowerCase())} className="login-button" >Submit</button>
			</div>
			<div className="login-back-button-container" onClick={ () => setSubPage('Login') }>
				<BsArrowLeftCircle />
			</div>
		</>
	)
}

function ResetPassword(props){
	const {
		emailRef,
		passRef,
		setSubPage,
		handleEnter,
		handleReset
	} = props;

	const [ stage, setStage ] = useState('initial');
	const [ question, setQuestion ] = useState('');
	const [ questionList, setQuestionList ] = useState([]);
	const [ confirmPass, setConfirmPass ] = useState('');
	const [ emailState, setEmailState ] = useState('');
	const questionRef = useRef();
	const newPassRef = useRef();
	const confirmPassRef = useRef();

	// useEffect(() => {
	// 	console.log(confirmPassRef.current);
		// if( confirmPass !== '' && confirmPassRef.current.value !== confirmPass ){
		// 	confirmPassRef.current.style.borderColor = 'red';
		// 	console.log('hi');
		// }
	// }, [newPassRef, confirmPassRef, confirmPass]);

	const checkPassValues = useCallback(() => {
		let cfmPassVal = confirmPassRef.current.value;
		if( cfmPassVal ){
			if( cfmPassVal !== newPassRef.current.value ){
				confirmPassRef.current.classList.remove('valid-new-password');
				confirmPassRef.current.classList.add('invalid-new-password');
			} else if( cfmPassVal === newPassRef.current.value ){
				confirmPassRef.current.classList.remove('invalid-new-password');
				confirmPassRef.current.classList.add('valid-new-password');
			}
		} else {
			confirmPassRef.current.classList.remove('invalid-new-password');
			confirmPassRef.current.classList.remove('valid-new-password');
		}
	}, [ newPassRef, confirmPassRef ]);

	const getQuestion = useCallback(() => { //method that retrieves the users security question
		props.setState({ loading: true });
		axios({
			method:'post',
			url: lovloginpath,
			headers: { 'Content-Type': 'application/json' },
			data: {
				endpoint:'info',
				table:'users',
				action:'question',
				name: emailRef.current.value.trim().toLowerCase(),
				kind: passRef.current.value
			}
		}).then( (response) => {
			let data = response.data.data;
			console.log(data);
			if( data[0].Message !== "invalid" ){
				if(data[0].Question !== null){
					setQuestion( data[0].Question );
					setStage('new_password');
					setEmailState( emailRef.current.value.trim().toLowerCase() );
					props.setState({ loading: false });
				} else {
					props.setState({
						loadingMessage: "A problem occurred while attempting to initiate your password reset.",
						loadingSuccess: false,
						loading: false
					});
				}
			} else {
				props.setState({
					loadingMessage: "Invalid email or password.",
					loadingSuccess: false,
					loading: false
				});
			}
		});
	}, [ passRef, emailRef ]);

	const setNewPassword = useCallback(() => {
		console.log("HELLO");
		props.setState({ loading: true });
		axios({
			method: 'post',
			url: lovloginpath,
			headers: { 'Content-Type': 'application/json' },
			data: {
				endpoint: 'create',
				table: 'users',
				misc: 'password',
				email: emailState,
				answer: questionRef.current.value.trim().toLowerCase(),
				status: newPassRef.current.value
			}
		}).then((response)=>{
			let data = response.data.data;
			console.log(data);
			if( data.Message === 'success' ){
				props.setState({
					loadingMessage: "Your password has successfully been reset.",
					loadingSuccess: true,
					loading: false
				});
				axios({
					method: 'post',
					url: bedloginpath.replace('login', 'changePassword'),
					data: {
						email: emailState,
						password: newPassRef.current.value,
						app: "BED",
						location: "Irvine"
					}
				}).then( res => {
					console.log(res);
				}).catch( err => console.log(err));
				setStage('initial');
				setSubPage('Login');
			} else {
				props.setState({
					loadingMessage: "Your password reset has failed.",
					loadingSuccess: false,
					loading: false
				});
			}
		}).catch( err => {
			props.setState({
				loadingMessage: "A problem occurred while attempting to initiate your password reset.",
				loadingSuccess: false,
				loading: false
			});
		});
	}, [ newPassRef, questionRef, emailState ]);

	const params = useMemo(() => {
		let callback;
		if( stage === 'initial' ){
			callback = getQuestion;
		} else if( stage === 'new_password' ){
			callback = setNewPassword;
		}

		return {
			stage: stage,
			callback: callback,
			setState: props.setState
		};

	}, [ stage, getQuestion, setNewPassword ]);

	return (
		<>
			{
				stage === 'initial' &&
				<>
					<div className="login-icon-container lock-icon">
						<CiLock />
					</div>
					<h3 className="my-keys-signin-title">Reset Password</h3>
					
					<div className="login-input-container">  
						<div>
							<label className="login-input-label" name="userName" id="userName">Email</label>
						</div>
						<div>
							<input className="login-input" id="user" type="text" ref={emailRef}/>
						</div>			
					</div>
					<div className="login-input-container">
						<div>
							<label className="login-input-label" id="password" >Password</label>
						</div>
						<div>
							<input className="login-input" type="password" id="pass" ref={passRef} onKeyDown={(e) => handleEnter(e, stage, getQuestion)}/>
						</div>			
					</div>
					<div className="login-buttons-container">
						<button onClick={() => handleReset(params)} className="login-button" >Submit</button>
					</div>
				</>
			}
			{
				stage === 'new_password' &&
				<>
					<div className="login-input-container" style={{paddingTop: '3rem'}}>  
						<div>
							<label className="login-input-label" name="security_question" id="securityQuestionLabel">{ question }?</label>
						</div>
						<div>
							<input className="login-input" id="securityQuestion" type="text" ref={questionRef}/>
						</div>			
					</div>
					<div className="login-input-container">  
						<div>
							<label className="login-input-label" name="new_password" id="newPasswordLabel">New Password</label>
						</div>
						<div>
							<input className="login-input" id="newPassword" type="password" ref={newPassRef}/>
						</div>			
					</div>
					<div className="login-input-container">  
						<div>
							<label className="login-input-label" name="confirm_password" id="confirmPasswordLabel">Confirm Password</label>
						</div>
						<div>
							<input className="login-input" id="confirmPassword" type="password" ref={confirmPassRef} onKeyUp={ checkPassValues } onKeyDown={(e) => handleEnter(e, stage, setNewPassword)} />
						</div>			
					</div>
					<div className="login-buttons-container">
						<button onClick={() => handleReset(params)} className="login-button" >Submit</button>
					</div>
				</>
			}
			<div className="login-back-button-container" onClick={ () => setSubPage('Login') }>
				<BsArrowLeftCircle />
			</div>
		</>
	)
}

function SetUserLogin(props){
	const [ question, setQuestion ] = useState('');
	const [ questions, setQuestions ] = useState([]);
	const [ confirmPass, setConfirmPass ] = useState('');
	const [ questionId, setQuestionId ] = useState(0);
	const [ answer, setAnswer ] = useState('');
	const answerRef = useRef();
	const newPassRef = useRef();
	const confirmPassRef = useRef();

	const checkPassValues = useCallback(() => {
		let cfmPassVal = confirmPassRef.current.value;
		if( cfmPassVal ){
			if( cfmPassVal !== newPassRef.current.value ){
				confirmPassRef.current.classList.remove('valid-new-password');
				confirmPassRef.current.classList.add('invalid-new-password');
			} else if( cfmPassVal === newPassRef.current.value ){
				confirmPassRef.current.classList.remove('invalid-new-password');
				confirmPassRef.current.classList.add('valid-new-password');
			}
		} else {
			confirmPassRef.current.classList.remove('invalid-new-password');
			confirmPassRef.current.classList.remove('valid-new-password');
		}
	}, [ newPassRef, confirmPassRef ]);

	const handleQuestionSelect = (e) => {
		setQuestion(e.target.value);
		const id = questions.find( q => q.Question === e.target.value ).Id;
		setQuestionId(id);
		console.log(id);
	}

	const handleSubmit = () => {
		const pass = newPassRef.current.value;
		const confirmPass = confirmPassRef.current.value;
		newPassRef.current.classList.remove('invalid-new-password');
		answerRef.current.classList.remove('invalid-new-password');
		if( pass.trim() === '' ){
			newPassRef.current.classList.add('invalid-new-password');
		}
		if( answer.trim() === '' ){
			answerRef.current.classList.add('invalid-new-password');
		}
		if( 
			pass.trim() !== ''
			&& confirmPass.trim !== ''
			&& pass === confirmPass
			&& answer.trim() !== ''
		){
			props.setState({ loading: true });
			axios({
				method:'post',
				url: lovloginpath,
				headers: { 'Content-Type':'application/json' },
				data: {
					endpoint: 'create',
					table: 'users',
					misc: 'security',
					status: pass,
					answer: answer.trim().toLowerCase(),
					firstID: props.userID,
					secondID: questionId
				}
			}).then((response)=>{
				let data = response.data.data;
				if(data.Message === "success"){
					props.setState({
						loading: false,
						loadingSuccess: true,
						page: 'LandingPage'
					});
				} else {
					props.setState({
						loadingMessage: data.Message,
						loadingSuccess: false,
						loading: false
					});
				}
			}).catch( err => {
				props.setState({
					loadingMessage: "A problem occurred while attempting to set your security info.",
					loadingSuccess: false,
					loading: false
				});
			});
		}
	}

	useEffect(()=>{
		axios({
			method:'post',
			url:lovloginpath,
			headers:{ 'Content-Type': 'application/json' },
			data:{ endpoint:'info', table:'question' }
		}).then((response)=>{
			let data = response.data.data;
			setQuestions(data);
			console.log(data);
			setQuestion(data[0].Question);
			setQuestionId(data[0].Id);
		});
	}, [setQuestions, setQuestion])

	return(
		<>
			{/* <div className="login-icon-container lock-icon">
				<CiLock />
			</div> */}
			{/* <h3 className="my-keys-signin-title">Security Info</h3> */}
			<div className="login-input-container">  
				<div>
					<label className="login-input-label" name="new_password" id="newPasswordLabel">New Password</label>
				</div>
				<div>
					<input className="login-input" id="newPassword" type="password" ref={newPassRef}/>
				</div>			
			</div>
			<div className="login-input-container">  
				<div>
					<label className="login-input-label" name="confirm_password" id="confirmPasswordLabel">Confirm Password</label>
				</div>
				<div>
					<input className="login-input" id="confirmPassword" type="password" ref={confirmPassRef} onKeyUp={ checkPassValues } />
				</div>		
			</div>
			<div className="login-input-container">  
				<div>
					<label className="login-input-label" name="security_question" id="questionLabel">Security Question</label>
				</div>
				<div>
					<select className="login-input" id="question" onChange={ (e) => handleQuestionSelect(e) }>
						{
							questions.map(q => {
								return <option key={q.Question}>{q.Question}</option>;
							})
						}
					</select>
				</div>			
			</div>
			<div className="login-input-container">  
				<div>
					<label className="login-input-label" name="answer" id="answerLabel">Security Answer</label>
				</div>
				<div>
					<input className="login-input" id="answer" type="text" ref={ answerRef } onChange={(e) => setAnswer(e.target.value)}/>
				</div>		
			</div>
			<div className="login-buttons-container">
				<button onClick={() => handleSubmit()} className="login-button" >Submit</button>
			</div>
		</>
	);
}