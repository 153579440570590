import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../images/logo.png';
import GF_white_logo from '../images/GF_white_logo.png';
import bar from '../images/bar.jpg'
import  '../styles.css';
const HeaderMain=()=>{

    return(
      <>
        <Navbar  collapseOnSelect expand="lg" style={{backgroundColor:'#1965A3'}} variant="light" fixed="top" sticky="top">
          <Navbar.Brand  style={{color:'#1965A3'}} href="#home">
            <div id="logo_container">
              <img id="logo" src={ GF_white_logo } />
            </div>
          </Navbar.Brand>
          <Navbar.Brand  style={{color:'#fff'}} href="#home">MyKeys@GF </Navbar.Brand>
         
          <Navbar.Toggle aria-controls="responsive-navbar-nav " />
          <Navbar.Collapse id="responsive-navbar-nav">
          
            <Nav  className="me-auto ">

            </Nav>
            <Nav>
             
              {/* <Nav.Link style={{color:' #003E51'}} href="#features">About Us</Nav.Link>
              <Nav.Link style={{color:' #003E51'}} href="#pricing">Contact Us</Nav.Link> */}
             {/*} <NavDropdown style={{color:' #00629B'}} title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item  style={{color:' #00629B'}} href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item  style={{color:' #00629B'}} href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item style={{color:' #00629B'}} href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item style={{color:' #00629B'}} href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
                 </NavDropdown>*/}
              
                {/* <Nav.Link style={{color:' #00629B'}} eventKey={2} href="#memes">
                Login
                </Nav.Link>
                <Nav.Link style={{color:' #00629B'}} href="#"><Mydate/></Nav.Link> */}
              
            </Nav>
            
          </Navbar.Collapse>
      </Navbar>
      {/* <div id="banner_container">
        <img src={ bar } id="banner" />
      </div> */}
    </>
    );
}
export default HeaderMain;
