import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { BsCheck2, BsXLg } from 'react-icons/bs';
import './Modal.css';

export default function CustomModal(props){
    const { loading, loadingMessage, success } = props;
    let iconBoxClass, icon, btnClass, modalTitle;
    if( success ){
        iconBoxClass = "icon-box-success";
        icon = <BsCheck2 />;
        btnClass = "btn btn-block btn-success";
        modalTitle = "Success!";
    } else {
        iconBoxClass = "icon-box-fail";
        icon = <BsXLg />;
        btnClass = "btn btn-block btn-danger";
        modalTitle = "Error";
    }
    return (
        <Modal
            show={ loading || ( loadingMessage.trim() !== '' ) }
            onHide={ () => props.setState({ loadingMessage: '' }) }
            dialogClassName="custom-modal"
        >
            <Modal.Body>
                {
                    loading ?
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    :
                    <div className="modal-dialog modal-confirm" role="status">
                        <div className="modal-content">
                            <div className="custom-modal-header">
                                <div className={ iconBoxClass }>
                                   <i>{ icon }</i>
                                </div>				
                                <h4 className="modal-title w-100">{ modalTitle }</h4>
                            </div>
                            <div className="modal-body">
                                <p className="text-center">{ loadingMessage }</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className={ btnClass }
                                    data-dismiss="modal"
                                    onClick={ () => props.setState({ loadingMessage: '' }) }
                                >OK</button>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
};